<!-- 新增智囊团 -->
<template>
  <div class='box-card'>
    <el-form :model="batchAddForm" :rules="batchAddFormRules" ref="batchAddFormRef" label-width="120px" class="form_box">
      <el-form-item label="参谋名称：" prop="staff_name">
        <el-input v-model="batchAddForm.staff_name" placeholder="请输入参谋名称" maxlength="100">
        </el-input>
      </el-form-item>
      <el-form-item label="职责描述：" prop="duty_desc">
        <el-input type="textarea" v-model="batchAddForm.duty_desc" :rows="3" placeholder="请输入职责描述"></el-input>
      </el-form-item>
      <el-form-item label="提示词：" prop="marked_words">
        <el-input v-model="batchAddForm.marked_words" placeholder="请输入提示词" maxlength="100">
        </el-input>
      </el-form-item>
      <el-form-item label="属性标签：" prop="tag_id">
        <div class="tagBox">
          <el-select popper-class="my-select" v-model="batchAddForm.tag_id" filterable clearable default-first-option
            placeholder="请选择属性标签">
            <el-option v-for="item in setTagsList" :key="item._id" :label="item.tag_name" :value="item._id">
            </el-option>
          </el-select>
          <lbButton class="edtiButton" type="setting" icon="shebeipeizhi" hint="配置" @click="isShowSetTags = true"></lbButton>
        </div>
      </el-form-item>
      <el-form-item label="头像：" prop="head_photo">
        <el-popover placement="right-end" width="300" trigger="click" v-model="photoVisible">
          <div class="innerImgChoose">
            <div class="chooseItem" v-for="(item, index) in 15" :key="index">
              <div :style="{ backgroundImage: 'url(' + require(`@/assets/img/juewu/avater${index}.png`) + ')' }"
                class="chooseDefaultImg" @click="selectDefaultImg(index)"></div>
            </div>
            <div class="chooseItem">
            <el-upload class="avatar-uploader" accept="image/jpeg,image/jpg,image/png" action="" :http-request="upload" :show-file-list="false" :before-upload="beforeAvatarUpload">
                <span>
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </span>
              </el-upload>
            </div>
          </div>
          <div class="img_box" slot="reference">
            <el-image class="upLoadImg" fit="cover" :src="batchAddForm.head_photo"
              v-if="batchAddForm.head_photo"></el-image>
            <span v-else>
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </span>
          </div>
        </el-popover>
      </el-form-item>
      <el-form-item label="数据源：" prop="data_origins">
        <el-popover placement="bottom-start" width="600" trigger="click">
          <el-table ref="multipleTable" :data="originDataList" @selection-change="selectionChange" row-key="id">
            <el-table-column type="selection" width="50" align="center" header-align="center" :reserve-selection="true"></el-table-column>
            <el-table-column width="300" property="origin_name" label="数据源"></el-table-column>
            <el-table-column width="250" property="rigin_type" label="类型">
              <template slot-scope="scope">
                <span>{{ scope.row.origin_name }}</span>
                <!-- <span>{{ getWayName("PLPlateType", scope.row.rigin_type) }} </span> -->
              </template>
            </el-table-column>
          </el-table>
          <div class="originDataBox" slot="reference">
            <template v-if="selectedOriginNameList.length>0">
              <span> 查看配置</span>
            </template>
            <template v-else>
              <span style="color:#c2c6cd;">请选择数据源</span>
            </template>
          </div>
        </el-popover>
      </el-form-item>
    </el-form>
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm" v-preventReClick>保 存
    </lbButton>
    <!-- 配置属性标签 -->
    <lebo-dialog append-to-body title="配置属性标签" :isShow="isShowSetTags" width="40%" @close="closeShowSetTags" footerSlot>
      <attributeBox @closeDialog="closeShowSetTags" v-if="isShowSetTags" :id="dialogShowSetTagsId"></attributeBox>
    </lebo-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import attributeBox from '../attributeBox/index.vue'
import request from '@/api/systemapi'
import { getBrainOriginList, getBrainTagList, addNewBrainHelp, editBrainHelp, getBrainHelpDetail } from '@/api/juewuApi.js'
export default {
  name: 'addBrainTrust',
  components: { attributeBox },
  props: ['id'],
  data () {
    return {
      // 表单数据
      batchAddForm: {
        staff_name: '', // 参谋名
        tag_id: '', // 属性标签id
        duty_desc: '', // 职责描述
        marked_words: '', // 提示词
        head_photo: '', // 头像
        is_default: false, // 是否默认
        data_origins: [], // 数据源id
        is_active: false // 是否禁用
      },
      // 数据源列标
      originDataList: [],
      selectedOriginNameList: [],
      isShowSetTags: false, // 配置属性标签
      setTagsList: [], // 属性标签
      dialogShowSetTagsId: '',
      photoVisible: false, // 选择默认头像
      // 校验规则
      batchAddFormRules: {
        staff_name: [
          { required: true, message: '请输入参谋名', trigger: 'blur' }
        ],
        duty_desc: [
          { required: true, message: '请输入职责描述', trigger: 'blur' }
        ],
        marked_words: [
          { required: true, message: '请输入提示词', trigger: 'blur' }
        ],
        tag_id: [
          { required: true, message: '请选择属性标签', trigger: 'blur' }
        ],
        head_photo: [
          { required: true, message: '请选择参谋头像', trigger: 'blur' }
        ]
      }
      // 默认头像数组

    }
  },
  watch: {},
  computed: {
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  methods: {
    // 获取数据源列表
    async fnGetBrainOriginList () {
      const res = await getBrainOriginList()
      console.log('数据源res', res)
      if (res && res.Code === 200) {
        this.originDataList = res.Data
        this.originDataList.forEach((element, elementIndex) => {
          this.batchAddForm.data_origins.forEach((item, itemIndex) => {
            if (element._id === item) {
              this.$refs.multipleTable.toggleRowSelection(this.originDataList[elementIndex], true)
            }
          })
        })
        console.log('this.batchAddForm.data_origins', this.batchAddForm.data_origins)
      }
    },
    // 保存
    submitAddForm () {
      this.$refs.batchAddFormRef.validate((valid) => {
        if (valid) {
          console.log('batchAddForm', this.batchAddForm)
          if (this.id) {
            this.fneditBrainHelp()
          } else {
            this.fnaddNewBrainHelp()
          }
        } else {
          return false
        }
      })
    },
    // 选中的问答
    selectionChange (list) {
      console.log('list', list)
      this.selectedOriginNameList = []
      this.batchAddForm.data_origins = []
      list.forEach(element => {
        this.selectedOriginNameList.push(element.origin_name)
        this.batchAddForm.data_origins.push(element._id
        )
      })
    },
    // 配置属性标签
    setTags () {

    },
    // 获取属性标签
    async fngetBrainTagList () {
      const res = await getBrainTagList()
      if (res && res.Code === 200) {
        this.setTagsList = res.Data
      }
    },
    // 关闭配置属性标签
    closeShowSetTags () {
      this.isShowSetTags = false
      this.fngetBrainTagList()
    },
    // 选择默认头像
    selectDefaultImg (index) {
      const defaultImgIndex = require(`@/assets/img/juewu/avater${index}.png`)
      this.imgUrlToBase64(defaultImgIndex)
    },
    imgUrlToBase64 (url) {
      return new Promise((resolve, reject) => {
        if (/\.(png|jpe?g|gif|svg)(\?.*)?$/.test(url)) {
          // 图片地址
          const image = new Image()
          // 设置跨域问题
          image.setAttribute('crossOrigin', 'anonymous')
          // 图片地址
          image.src = url
          image.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            canvas.width = image.width
            canvas.height = image.height
            ctx.drawImage(image, 0, 0, image.width, image.height)
            // 获取图片后缀
            const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase()
            // 转base64
            const dataUrl = canvas.toDataURL(`image/${ext}`)
            console.log('dataUrl', dataUrl)
            this.fnFaceRecognition('default.png', dataUrl)
            resolve(dataUrl || '')
          }
        } else {
          // 非图片地址
        }
      })
    },
    // 触发选择图片按钮
    upload (item) {
      console.log(item)
      var that = this
      const indexpoint = item.file.name.lastIndexOf('.')
      const imageName = item.file.name.substr(indexpoint)
      const src = window.URL.createObjectURL(item.file)
      var reader = new FileReader()
      reader.onload = async (e) => {
        // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
        // 使用axios的post方法上传即可
        var base64image = e.target.result
        that.fnFaceRecognition(item.file.name, base64image)
      }
      reader.readAsDataURL(item.file)
    },
    // 获取图片地址
    async fnFaceRecognition (name, baseImg) {
      const res = await request.updataIcon({ name: name, strbase64: [baseImg] })
      if (res) {
        this.batchAddForm.head_photo = res.data[0].FilePath
      }
      this.photoVisible = false
    },
    // 新增智囊团
    async fnaddNewBrainHelp () {
      const res = await addNewBrainHelp(this.batchAddForm)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    // 编辑智囊团
    async fneditBrainHelp () {
      this.batchAddForm._id = this.id
      const res = await editBrainHelp(this.batchAddForm)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    // 获取智囊团详情
    async fngetBrainHelpDetail () {
      const res = await getBrainHelpDetail({ _id: this.id })
      if (res && res.Code === 200) {
        this.batchAddForm.staff_name = res.Data.staff_name
        this.batchAddForm.tag_id = res.Data.tag_id
        this.batchAddForm.duty_desc = res.Data.duty_desc
        this.batchAddForm.marked_words = res.Data.marked_words
        this.batchAddForm.head_photo = res.Data.head_photo
        this.batchAddForm.data_origins = res.Data.data_origins
      }
      this.fnGetBrainOriginList()
      this.fngetBrainTagList()
    }
  },
  created () { },
  mounted () {
    if (this.id) {
      this.fngetBrainHelpDetail()
      // 编辑时将请求放到获取详情之后
    } else {
      this.fnGetBrainOriginList()
      this.fngetBrainTagList()
    }
  }
}
</script>

<style scoped lang="less">
.box-card {
  position: relative;
  // padding:0 30px;
  padding-bottom: 30px;
  box-sizing: border-box;

  .form_box {
    max-height: 650px;
    overflow-y: auto;
    overflow-x: hidden;

    .img_box {
      width: 100px;
      height: 100px;
      font-size: 18px;
      line-height: 100px;
      text-align: center;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      cursor: pointer;
      .el-image {
        border-radius: 8px;
        width: 100px;
        height: 100px;
      }
    }

    .tagBox {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .edtiButton {
        margin-left: 10px;
        // width: 36px;
        // height: 36px;
        // border-radius: 5px 5px 5px 5px;
        // border: 1px solid #999999;
        cursor: pointer;
      }

    }
    .originDataBox{
      width: 90%;
      height: 40px;
      background-color: #FFF;
      background-image: none;
      border-radius: 5px;
      border: 1px solid #DCDFE6;
      padding-left: 12px;
      box-sizing: border-box;
    }
    /deep/.el-form-item__content {
      .el-select {
        width: 90%;
       .el-input {
          width: 100%!important;
        }
      }

      .el-input {
        width: 90%;
      }

      .el-textarea {
        width: 90%;
      }
    }
  }

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}

.innerImgChoose {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

  .chooseItem {
    margin: 10px;
    width: 50px;
    height: 50px;
    background: #FFFFFF;

    &:hover {
      border-color: #01A6FB;
    }
    .avatar-uploader{
      text-align: center;
      line-height: 50px;
      border-radius: 6px;
      border: 1px solid #E6E6E6;
      /deep/.el-upload {
        width: 100%;
        height: 100%;
      }
    }
    .chooseDefaultImg {
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}</style>
