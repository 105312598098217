<!-- 配置属性标签 -->
<template>
  <div class='tagContent'>
    <el-table :data="tableData" style="width: 100%" class="tagTable" max-height="500">
      <el-table-column label="属性标签" prop="tag_name">
        <template slot-scope="scope">
          <el-input v-if="scope.row.isEdit" v-model="editIngTagName" placeholder="请输入" @blur="saveEditTag(scope.row)"
            :id="scope.row._id" maxlength="15"></el-input>
          <div v-else class="txt">{{ scope.row.tag_name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <lbButton type="warning" icon="bianji" hint="编辑" @click="handleEdit(scope.$index, scope.row)"></lbButton>
          <lbButton type="err" icon="shanchu1" hint="删除" @click="handleDelete(scope.$index, scope.row)">
          </lbButton>
        </template>
      </el-table-column>
    </el-table>
    <div class="buttonBox">
      <lbButton icon="xinzeng" @click="addNewTag()" class="checkButton">新增
      </lbButton>
    </div>
  </div>
</template>

<script>
import { getBrainTagList, editBrainTag, delBrainTag, addNewBrainTag } from '@/api/juewuApi.js'
export default {
  name: 'attributeBox',
  components: {},
  props: {},
  data () {
    return {
      tableData: [],
      editIngTagName: ''// 编辑属性标签名
    }
  },
  watch: {},
  computed: {},
  methods: {
    // 获取属性标签
    async fngetBrainTagList () {
      const res = await getBrainTagList()
      console.log('res', res)
      if (res && res.Code === 200) {
        res.Data.forEach(element => {
          element.isEdit = false
        })
        this.tableData = res.Data
      }
    },
    // 编辑属性标签
    handleEdit (index, row) {
      console.log(index, row)
      this.editIngTagName = row.tag_name
      row.isEdit = true
      this.$nextTick(() => {
        const catchId = row._id
        const inputDom = document.getElementById(catchId)
        inputDom.focus()
      })
    },
    async handleDelete (index, row) {
      const res = await delBrainTag({
        _id: row._id
      })
      this.fngetBrainTagList()
    },
    // 失去焦点保存编辑
    async saveEditTag (row) {
      console.log('rowsq', row)
      if (this.editIngTagName) {
        if (row.isNew) {
          const res = await addNewBrainTag({
            tag_name: this.editIngTagName
          })
        } else {
          const res = await editBrainTag({
            _id: row._id,
            tag_name: this.editIngTagName
          })
        }
        this.fngetBrainTagList()
        this.editIngTagName = ''
        row.isEdit = false
      } else {
        if (row.isNew) {
          this.tableData.pop()
        } else {
          this.editIngTagName = ''
          row.isEdit = false
        }
      }
    },
    // 新增属性标签
    addNewTag () {
      this.tableData.push({
        isEdit: true,
        _id: 'new' + Date.now(),
        isNew: true
      })
      this.$nextTick(() => {
        const catchId = this.tableData[this.tableData.length - 1]._id
        const inputDom = document.getElementById(catchId)
        inputDom.focus()
      })
    }
  },
  created () { },
  mounted () {
    this.fngetBrainTagList()
  }
}
</script>

<style scoped lang="less">
.tagTable {
  margin-bottom: 20px;
}

.buttonBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .checkButton{
    width: 100%;
  }
}
</style>
