<!-- 智囊团 -->
<template>
  <div class='content' style="margin-top: 20px;">
    <div class="box-card" style="text-align: left">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="参谋：">
          <el-input placeholder="请输入参谋" v-model="queryForm.staff_name" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item label="提示词：">
          <el-input placeholder="请输入提示词" v-model="queryForm.marked_words" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click=" onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="智囊团">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel"  v-if="isShowBtn('5491175b5d464941826f7104')">删除
            </lbButton>
            <lbButton icon="xinzeng" @click="openAddForm()"  v-if="isShowBtn('5491175b5d464941826f7103')">新增
            </lbButton>
          </div>
        </template>
        <!-- 主要内容 -->
        <div>
          <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
            tooltip-effect="dark" border stripe @selection-change="selectionChange">
            <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
            <el-table-column prop="staff_name" label="参谋"></el-table-column>
            <el-table-column prop="duty_desc" label="职责描述"></el-table-column>
            <el-table-column prop="marked_words" label="提示词"></el-table-column>
            <el-table-column prop="is_default" label="类型">
              <template slot-scope="scope">
                {{ scope.row.is_default ? '默认' : "自定义" }}
              </template>
            </el-table-column>
            <el-table-column prop="is_active" label="状态" width="150">
              <template slot-scope="scope">
                <lebo-switch :status="!scope.row.is_active" :turnOn="''" :turnOff="''" :switchType="scope.row.is_active" v-if="isShowBtn('5491175b5d464941826f7105')"
                  @switchChange="changeStatus(scope.row)"></lebo-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <lbButton type="warning" icon="bianji" hint="编辑" @click="handleit(scope.row)" v-if="isShowBtn('5491175b5d464941826f7106')"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <!-- 页码 -->
          <el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="queryForm.PageIndex"
            :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="page.total"></el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 新增编辑智囊团 -->
    <lebo-dialog append-to-body :title="dialogBrainTrustId ? '编辑智囊团' : '新增智囊团'" :isShow="showAddBrainTrust" width="55%"
      @close="showAddBrainTrust = false" footerSlot>
      <addBrainTrust @closeDialog="closeBrainTrust" v-if="showAddBrainTrust" :id="dialogBrainTrustId"></addBrainTrust>
    </lebo-dialog>
  </div>
</template>

<script>
import { getBrainList, editBrainHelpStatus, delBrainHelp } from '@/api/juewuApi.js'
import addBrainTrust from './addBrainTrust/index.vue'
export default {
  name: 'brainTrust',
  components: { addBrainTrust },
  props: {},
  data () {
    return {
      // 查询表单
      queryForm: {
        staff_name: '', // 参谋
        marked_words: '', // 提示词
        PageSize: 10,
        PageIndex: 1
      },
      tableData: [],
      page: {
        total: 0,
        pageSize: 10,
        currentPage: 1
      },
      showAddBrainTrust: false, // 展示新增智囊团
      dialogBrainTrustId: '', // 智囊团id
      multipleSelection: []
    }
  },
  watch: {},
  computed: {},
  methods: {
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetBrainList()
    },
    // 每页条数改变
    sizeChange (val) {
      this.page.currentPage = 1
      this.page.pageSize = val
      this.queryForm.PageSize = val
      this.fnGetBrainList()
    },
    // 页码改变
    currentChange (val) {
      this.queryForm.PageIndex = val
      this.page.currentPage = val
      this.fnGetBrainList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // 选中的问答
    selectionChange (list) {
      this.multipleSelection = list
    },
    // 获取智囊团分页列表
    async fnGetBrainList () {
      const res = await getBrainList(this.queryForm)
      if (res && res.Code === 200) {
        console.log('智囊团分页res', res)
        this.tableData = res.Data.DataList
        this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      }
    },
    // 删除智囊团
    batchDel () {
      if (this.multipleSelection.length > 0) {
        const activeStaff = []
        this.multipleSelection.forEach(item => {
          if (item.is_active) {
            activeStaff.push(item.staff_name)
          }
        })
        if (activeStaff.length > 0) {
          this.$confirm(activeStaff.join('，'), '请先关闭智囊团', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
          }).catch(() => {
          })
        } else {
          this.$confirm('此操作将永久删除选中的智囊团, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
          // this.$msg.success('删除成功!');
            var obj = {}
            var arr = []
            console.log('this.multipleSelection', this.multipleSelection)
            this.multipleSelection.forEach(element => {
              arr.push(element._id)
            })
            this.fndelBrainHelp(arr)
          }).catch(() => {
            this.$msg.info('已取消删除')
          })
        }
      } else {
        this.$msg.warning('请选择要删除的智囊团！')
      }
    },
    // 删除智囊团
    async fndelBrainHelp (list) {
      const res = await delBrainHelp(list)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetBrainList()
    },
    // 新增智囊团
    openAddForm () {
      this.dialogBrainTrustId = ''
      this.showAddBrainTrust = true
    },
    // 编辑智囊团
    handleit (row) {
      this.dialogBrainTrustId = row._id
      this.showAddBrainTrust = true
    },
    // 关闭弹窗刷新
    closeBrainTrust () {
      this.showAddBrainTrust = false
      this.fnGetBrainList()
    },
    // 改变智囊团状态
    async changeStatus (row, type) {
      console.log('row', row)
      console.log('type', type)
      if (row.is_default) {
        this.$msg.warning('默认参谋不可关闭！')
      } else {
        const obj = {}
        obj._id = row._id
        obj.is_active = !row.is_active
        await editBrainHelpStatus(obj)
        this.fnGetBrainList()
      }
    }
  },
  created () { },
  mounted () {
    this.fnGetBrainList()
  }
}
</script>

<style scoped></style>
